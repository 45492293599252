<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewTask"
          >
            New Task
          </button>
          <CButton
            @click="warningModalnewVersion = true"
            color="primary"
            style="center"
            class="my float-xl-right"
            >Import Version-2
          </CButton>
          <CModal
            title="File Upload"
            color="primary"
            :show.sync="warningModalnewVersion"
            centered
            size="lg"
            @update:show="saveTeamgroup"
          >
            <CInputFile
              label="Select File"
              horizontal
              type="file"
              @change="uploadVerionTwo"
              accept="xl/*"
            />
            <div class="vld-parent">
              <loading-overlay
                :active.sync="isLoading"
                :is-full-page="fullPage"
                :loader="loader"
                :color="color"
              ></loading-overlay>
            </div>
          </CModal>

          <CButton
            @click="warningModal = true"
            color="primary"
            style="center"
            class="my float-xl-right"
            >Import Version-1</CButton
          >
          <CModal
            title="File Upload"
            color="primary"
            :show.sync="warningModal"
            centered
            size="lg"
            @update:show="saveTeamgroup"
          >
            <CInputFile
              label="Select File"
              horizontal
              type="file"
              @change="uploadImage"
              accept="xl/*"
            />
            <div class="vld-parent">
              <loading-overlay
                :active.sync="isLoading"
                :is-full-page="fullPage"
                :loader="loader"
                :color="color"
              ></loading-overlay>
            </div>
          </CModal>
        </div>
        <CDataTable
          :items="items"
          :fields="fields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          ref="vuetable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>
          <template #fileId="{ item }">
            <td v-if="item.fileId">
              <img
                :src="getImageUrl(item.fileId)"
                style="max-width: 100px; max-height: 100px"
              />
            </td>
            <td v-else>--</td>
          </template>
          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'Items',
                    params: {
                      taskId: item.taskId,
                      checklistId: item.checklistId,
                      mode: 'view',
                    },
                  }"
                  >Items</CDropdownItem
                >
                <CDropdownItem
                  :to="{
                    name: 'Task',
                    params: { taskId: item.taskId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >
                <div>
                  <taskMandatory>
                    <!-- <p v-if="item.subGroupName == 'QC' "> 
                      <span class="Merge Users"
                        > Are you sure you want to Merge YC PPT Department Users ?</span>
                      <p>
                         <p v-if="item.subGroupName == 'YC' "> 
                      <span class="Merge Users"
                        > Are you sure you want to Merge QC PPT Department Users ?</span>
                      <p> -->
                    <!-- <div class="col-sm-9"> -->

                    <p>
                      <!-- <div class="col-sm-9"> -->

                      <select class="form-control" v-model="item.taskMandatory">
                        <!-- <option disabled value="">--Select One Option--</option> -->
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>

                      <!-- <select class="form-control">
                        <option disabled value="">--Select One Option--</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                      <br /> -->
                    </p>

                    <!-- </div> -->

                    <!-- </p> -->

                    <!-- <span class="col-md-2" v-if="item.mergeDepartment == true">
                      <input id="yes" type="checkbox" class="mr-2" checked/> Yes
                       <input id="no" type="checkbox" class="mr-2"  @click="uncheckNo(item.mergeDepartment)" /> No
                    </span> -->
                    <!-- <span class="col-md-2" v-else>
                      <input
                      id = "yes2"
                        type="checkbox"
                        class="mr-2"
                        @click="uncheckNo(item.mergeDepartment)"
                      />Yes
                    </span> -->

                    <!-- <span class="col-md-2" v-if="item.mergeDepartment == false">
                       <input id="yes" type="checkbox" class="mr-2"  @click="uncheckYes(item.mergeDepartment)" /> Yes
                      <input id="no" type="checkbox" class="mr-2" checked />No
                    </span> -->

                    <!-- <span class="col-md-2" v-else>
                      <input id = "no2"
                        type="checkbox"
                        class="mr-2"
                       
                      />No
                    </span> -->
                    <div>
                      <br />
                    </div>
                    <div align="right">
                      <CButton
                        class="mr-2"
                        type="submit"
                        size="sm"
                        color="primary"
                        @click="taskMandatory(item.taskId, item.taskMandatory)"
                        >Save</CButton
                      >
                      <CButton
                        class="mr-2"
                        type="Cancel"
                        size="sm"
                        color="danger"
                        @click="previous()"
                        >Cancel</CButton
                      >
                    </div>
                  </taskMandatory>
                </div>
                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteTask(item.taskId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
      <CCardFooter align="right">
        <CButton
          class="mr-2"
          type="Cancel"
          size="sm"
          color="danger"
          @click="cancel()"
        >
          <CIcon name="cil-ban" /> Cancel
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
const fields = [
  "index",
  { key: "taskName", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "fileId",
    label: "Image",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import taskMandatory from "../../containers/TaskMandatory.vue";

Vue.component("loading-overlay", Loading);
Vue.use(Loading);

export default {
  name: "Tasks",
  components: {
    ConfirmationModal,
    Loading,
    taskMandatory,
  },
  data() {
    return {
      // touchOpen: false,

      items: [],
      fields,

      details: [],
      collapseDuration: 0,
      warningModal: false,
      warningModalnewVersion: false,
      isLoading: false,
      fullPage: true,
      loader: "dots",
      color: "#0af0e0",
      width: "94",
      height: "54",

      // backgroundColor: '#997c7c',
      // task:{
      // fileId:""
      // },
    };
  },
  methods: {
    getTasks() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/tasks/" +
          this.$route.params.checklistId
      )
        .then((response) => response.json())
        .then(
          (data) => (
            console.log("data from api", data),
            ((this.items = data), console.log("items", this.items))
          )
        );
    },

    taskMandatory(taskId, taskMandatory) {
      console.log("task mandatory called with", taskId, taskMandatory);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/task/mandatory/" +
          taskId +
          "?taskMandatory=" +
          taskMandatory
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("task mandatory", data);

          window.location.reload();
          // console.log(window.location.reload());
        });
    },

    showModal() {},
    doAjax() {
      this.isLoading = true;
      // simulate AJAX
    },
    open() {
      console.log("open was clicked, will auto hide");
      let loader = this.$loading.show({
        color: "#000000",
        loader: "dots",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
      });
      setTimeout(() => loader.hide(), 5 * 1000);
    },
    show() {
      console.log("show was clicked, click to hide");
      // do AJAX here
      this.visible = true;
    },
    saveTeamgroup(flag, e, accept) {
      console.log("flag", flag, "e", e, "accept", accept);
      if (accept) {
        window.location.reload();
        //  this.$router.push({ name: "Checklists",  params: {subGroupId: this.$route.params.subGroupId,modelId: this.$route.params.modelId,groupId: this.$route.params.groupId } });
        //this.$router.push({ name: "Tasks",  params: {checklistId: this.$route.params.checklistId,modelId: this.$route.params.modelId ,subGroupId: this.$route.params.subGroupId,groupId: this.$route.params.groupId} });
      } else {
        window.location.reload();
        // this.$router.push({ name: "Checklists",  params: {subGroupId: this.$route.params.subGroupId,modelId: this.$route.params.modelId,groupId: this.$route.params.groupId } });
      }
    },

    deleteTask(taskId) {
      console.log("delete called with", taskId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/delete/task/" + taskId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTasks();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          console.log(window.location.reload);
          window.location.reload();
        });
    },
    uploadImage(files, event) {
      this.isLoading = true;
      const URL =
        process.env.VUE_APP_API_HOST +
        "/emapp/web/upload/excel/" +
        this.$route.params.checklistId;
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          this.isLoading = false;
        });
    },
    uploadVerionTwo(files, event) {
      this.isLoading = true;
      const URL =
        process.env.VUE_APP_API_HOST +
        "/emapp/web/upload/version2/excel/" +
        this.$route.params.checklistId;
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data",data);
          // this.isLoading = false;
        });
    },
    getImageUrl(fileId) {
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },
    navToNewTask() {
      this.$router.push({
        name: "Task",
        params: { checklistId: this.$route.params.checklistId, mode: "new" },
      });
    },
    navToImport() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/upload/excel/" +
          this.$route.params.checklistId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTasks();
          console.log("get Tasks", this.getTasks);
        });
    },

    cancel() {
      this.$router.push({
        name: "Checklists",
        params: {
          modelId: this.$route.params.modelId,
          subGroupId: this.$route.params.subGroupId,
          groupId: this.$route.params.groupId,
        },
      });
    },

    previous() {
      // window.history.go(0);
      // window.location.reload();
      //  this.$router.push('/tasks/' +this.$route.params.checklistId +'/'+ this.$route.params.modelId+'/'+this.$route.params.subGroupId +'/'+ this.$route.params.groupId).catch(() => {});
      //location.href=  this.$router.push('/tasks/' +this.$route.params.checklistId +'/'+ this.$route.params.modelId+'/'+this.$route.params.subGroupId +'/'+ this.$route.params.groupId);
      // this.$router.replace({ name: "Tasks"}).catch(()=>{});
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/tasks/" +
          this.$route.params.checklistId
      )
        .then((response) => response.json())
        .then(
          (data) => (
            console.log("data from api", data),
            ((this.items = data),
            console.log("items", this.items),
            window.location.reload())
          )
        );
    },
    // window.history.go(0);
    // this.$router.push({
    //   name: "Tasks",
    //   params: {
    //     modelId: this.$route.params.modelId,
    //     subGroupId: this.$route.params.subGroupId,
    //     groupId: this.$route.params.groupId,
    //   },
    // });
    // window.location.reload();
    // this.$router.go();
    // window.history.back();
    // window.location.assign("/tasks/412/13/3/1");
  },

  mounted() {
    this.getTasks();
  },
};
</script>

<style >
button {
  margin-left: 3px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
